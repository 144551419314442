const locale = {
  common: {
    or: 'or',
    input_email: 'Email',
    input_password: 'Password',
    social_login: 'Continue with {name}',
    required: 'Required',
    error_required: 'Required',
    error_captcha: 'Please verify you are human',
    per_page: 'per page',
    next: 'Next',
    prev: 'Prev',
    changes_saved: 'Changes Saved',
    loading_captcha: 'Loading CAPTCHA, Please wait...'
  },
  localization: { heading: 'Choose your country', action: 'Continue' },
  oauth: {
    wait_message: 'Please wait...',
    close_link: 'Go back to login'
  },
  login: {
    init_heading: 'Login or Create an account',
    init_subheading: 'Welcome to Harklinikken, enter your email to continue',
    init_email_placeholder: 'Enter your email here',
    continue_action: 'Continue',
    invalid_email: 'Invalid email format',
    heading: 'Login',
    subheading: 'Welcome back, Enter your credentials to login',
    action: 'Login',
    otp_input: 'One time code',
    otp_subheading: "We've sent a one time code to {email}, Please enter the code below.",
    otp_placeholder: 'Enter your one time code here',
    otp_note: 'Delivery of the OTP email could take a few minutes. {link}',
    otp_request_link: 'Request a new code',
    otp_login_error_heading: 'Login failed',
    otp_login_error: 'Please request a new one time code and try again.',
    passwordless_subheading:
      "We've sent a login link to {email}, please follow the instructions in that email.",
    forgot_password: 'Forgot your password?',
    no_account_prompt: "Don't have an account?",
    register_link: 'Create an account',
    message_login_facebook: 'Your account is linked with Facebook, click the button below to login',
    message_login_google: 'Your account is linked with Google, click the button below to login',
    message_login_social:
      "You've created your account with social login, please use the buttons below"
  },
  register: {
    heading: 'Create an account',
    subheading: 'By creating an account, you agree to the {terms} and {privacy}',
    terms: 'terms of service',
    privacy: 'privacy policy',
    opt_in_label: 'Yes, I would like to receive Harklinikken marketing emails.',
    action: 'Continue',
    login_prompt: 'Already have an account?',
    login_action: 'Log in',
    message_success: 'Account created, Please login to continue'
  },
  forgot_password: {
    heading: 'Forgot Password',
    body: "Please enter your email and we'll send you a link to reset your password",
    action: 'Request Reset Link',
    login_action: 'Go back to login'
  },
  reset_password: {
    heading: 'Reset Password',
    body: 'Please set your new password',
    input_new_password: 'New password',
    action: 'Update Password',
    forgot_action: 'Request a new reset link'
  },
  layout: {
    account_menu: 'Menu',
    manage_account: 'Manage Account',
    logout: 'Logout',
    sidebar_menu: 'Account Menu',
    link_overview: 'Overview',
    link_manage_account: 'Account Information',
    link_manage_addresses: 'Shipping Addresses',
    link_orders: 'Orders',
    link_subscriptions: 'Subscriptions',
    link_hair_assessment: 'Hair Assessment',
    link_refer: 'Refer a Friend',
    link_referrals: 'Referrals',
    link_go_to_store: 'Go back to store',
    error_multipass: 'Problem accessing your account, Please contact support',
    error_shopify: 'Problem fetching your store account, Please contact support',
    try_again: 'Try again',
    not_verified_heading: 'Verify your email',
    not_verified_body:
      'To keep things secure and make sure your account is protected, please verify your email by clicking the link in the welcome email we sent you.',
    not_verified_resend: 'Resend verification email'
  },
  verify: {
    submitting: 'Verifying...',
    success_message: 'Account verified',
    error_message: 'Failed to verify account',
    email_sent: 'Verification email sent',
    go_back: 'Go to my account'
  },
  passwordless: {
    submitting: 'Please wait...',
    error_message: 'Link has expired',
    go_back: 'Go to login'
  },
  dashboard: {
    title: 'My Account',
    recent_orders: 'Recent Orders',
    view_all_orders: 'View all orders',
    summary: 'Account Details',
    edit_link: 'Edit Account',
    addresses_link: 'Manage Addresses'
  },
  orders: {
    title: 'Orders',
    no_orders: "You've not made any orders yet",
    col_number: 'Order Number',
    col_date: 'Date',
    col_address: 'Address',
    col_status: 'Status',
    col_total: 'Total',
    view_order: 'View Order',
    status_cancelled: 'Canceled',
    status_fulfilled: 'Fulfilled',
    status_in_progress: 'In Progress',
    status_on_hold: 'On Hold',
    status_open: 'Open',
    status_partially_fulfilled: 'Partially Fulfilled',
    status_pending_fulfillment: 'Pending Fulfillment',
    status_restocked: 'Restocked',
    status_scheduled: 'Scheduled',
    status_processing: 'Processing'
  },
  order_view: {
    number: 'Order #{number}',
    print_action: 'Print',
    not_found: 'Order not found',
    details: 'Order Details',
    cancelled_title: `Order cancelled at {date}`,
    cancelled_reason: `Reason: {text}`,
    order_date: 'Order Date',
    payment_status: 'Payment Status',
    order_status: 'Order Status',
    customer: 'Customer',
    shipping_address: 'Shipping Address',
    billing_address: 'Billing Address',
    delivery: 'Delivery Details',
    delivery_by: 'Delivered by {company}',
    track_delivery: 'Track Delivery',
    products: 'Order Products',
    col_product: 'Product',
    col_quantity: 'Quantity',
    col_price: 'Price',
    col_subtotal: 'Subtotal',
    shipping: 'Shipping',
    tax: 'Tax',
    total: 'Total',
    refunded: 'Refunded',
    subscriptions: 'Order Subscriptions'
  },
  manage_account: {
    title: 'Manage Account',
    heading: 'Personal details',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    marketing_opt_in: "I'd like to receive marketing emails",
    save_action: 'Save Changes',
    change_password_error_mismatch: 'Current password is incorrect',
    change_password_error_incorrect: 'Incorrect password',
    change_password_error_generic: 'Problem updating password'
  },
  change_password: {
    heading: 'Change password',
    current: 'Current password',
    new: 'New password',
    confirm: 'Confirm new password',
    action: 'Update Password',
    error_no_match: "Passwords don't match",
    success_message: 'Password updated'
  },
  addresses: {
    title: 'Manage Addresses',
    add_address: 'Add Address',
    no_results: "You don't have any addresses yet",
    default_address: 'Default Address',
    edit_action: 'Edit',
    delete_action: 'Delete',
    delete_prompt: 'Are you sure you want to remove this address?',
    delete_popup_cancel: 'Cancel',
    delete_popup_confirm: 'Yes, Remove',

    success_deleted: 'Address deleted',
    error_deleting: 'Problem deleting address'
  },
  address_form: {
    heading_add: 'Add Address',
    heading_edit: 'Edit Address',
    first_name: 'First name',
    last_name: 'Last name',
    address_line_1: 'Address line 1',
    address_line_2: 'Address line 2',
    postcode: 'Postcode',
    city: 'City',
    state: 'State',
    country: 'Country',
    set_default: 'Set as default address',
    add_action: 'Create Address',
    edit_action: 'Save Changes',
    cancel_action: 'Cancel'
  },
  assessment: {
    title: 'Hair Assessment',
    no_assessment: "You've not completed a hair assessment yet",
    take_assessment: 'Take Hair Assessment',
    heading: 'Your latest hair assessment result',
    complete_date: 'Assessment made at {date}',
    recommended_title: 'Recommended for you',
    view_result: 'View Result'
  }
}

export default locale
