import type { DeepPartial } from 'har-shared/types/common'
import type { LocaleMessageSchema } from '@/locale/types'

const locale: DeepPartial<LocaleMessageSchema> = {
  common: {
    or: 'eller',
    input_email: 'E-mailadresse',
    input_password: 'Adgangskode',
    social_login: 'Fortsæt som {name}',
    required: 'Påkrævet',
    error_required: 'Påkrævet',
    error_captcha: 'Bekræft venligst, at du er et menneske',
    per_page: 'pr side',
    next: 'Næste',
    prev: 'Forrige',
    changes_saved: 'Ændringer gemt',
    loading_captcha: 'Indlæser CAPTCHA, vent venligst...'
  },
  localization: { heading: 'Vælg land', action: 'Fortsæt' },
  oauth: {
    wait_message: 'Vent venligst...',
    close_link: 'Gå tilbage til login'
  },
  login: {
    init_heading: 'Log ind eller Opret en konto',
    init_subheading: 'Log ind eller opret en konto. Indtast din e-mail for at fortsætte',
    init_email_placeholder: 'Indtast din e-mail her',
    continue_action: 'Fortsæt',
    invalid_email: 'Ugyldig e-mail',
    heading: 'Login',
    subheading: 'Velkommen tilbage. Indtast dine adgangsoplysninger for login',
    action: 'Login',
    otp_input: 'Engangskode',
    otp_subheading: 'Vi har sendt en engangskode til {email}, skriv venligst koden nedenfor.',
    otp_placeholder: 'Indtast din engangskode her',
    otp_note: 'Leveringen af OTP-e-mailen kan tage op til to minutter.',
    otp_request_link: 'Anmod om en ny kode',
    otp_login_error_heading: 'Login mislykkedes',
    otp_login_error: 'Anmod om en ny engangskode, og prøv igen.',
    passwordless_subheading:
      'Vi har sendt et link til {email}, følg venligst instruktionerne i mailen',
    forgot_password: 'Har du glemt din adgangskode?',
    no_account_prompt: 'Har du ikke en konto?',
    register_link: 'Opret en konto',
    message_login_facebook:
      'Din konto er knyttet til Facebook, klik på knappen nedenfor for at logge ind',
    message_login_google:
      'Din konto er knyttet til Google, klik på knappen nedenfor for at logge ind',
    message_login_social:
      'Du har oprettet din konto med socialt login, brug venligst knapperne nedenfor'
  },
  register: {
    heading: 'Opret Konto',
    subheading: 'Ved at oprette en konto erklærer du dig enig i {terms} og {privacy}',
    terms: 'brugsvilkår',
    privacy: 'privatlivspolitik',
    opt_in_label: 'Ja tak, jeg vil gerne modtage Hårklinikkens nyhedsbreve på e-mail.',
    action: 'Fortsætte',
    login_prompt: 'Har du allerede en konto?',
    login_action: 'Login',
    message_success: 'Kontoen er oprettet. Venligst login for at fortsætte'
  },
  forgot_password: {
    heading: 'Glemt adgangskode?',
    body: 'Indtast venligst din e-mail og vil vil sende dig et link til nulstilling af din adgangskode',
    action: 'Anmod om link til nulstilling',
    login_action: 'Gå tilbage til login'
  },
  reset_password: {
    heading: 'Nulstil adgangskode',
    body: 'Venligst indtast din nye adgangskode',
    input_new_password: 'Ny adgangskode',
    action: 'Opdateret adgangskode',
    forgot_action: 'Anmod om et nyt link til nulstilling'
  },
  layout: {
    account_menu: 'Menu',
    manage_account: 'Administrér konto',
    logout: 'Logout',
    sidebar_menu: 'Kontomenu',
    link_overview: 'Overblik',
    link_manage_account: 'Kontoinformation',
    link_manage_addresses: 'Leveringsadresse',
    link_orders: 'Ordre',
    link_subscriptions: 'Abonnementer',
    link_hair_assessment: 'Håranalyse',
    link_refer: 'Henvis en ven',
    link_referrals: 'Henvisninger',
    link_go_to_store: 'Tilbage til webshop',
    error_multipass: 'Problem med adgang til din konto, kontakt venligst support',
    error_shopify: 'Problem med at tilgå din konto, kontakt venligst support',
    try_again: 'Prøv igen',
    not_verified_heading: 'Bekræft din e-mail',
    not_verified_body:
      'For at sikre at din konto er beskyttet, bedes du bekræfte din e-mail ved at klikke på linket i velkomstmailen, vi sendte dig.',
    not_verified_resend: 'Gensend din bekræftelses e-mail'
  },
  verify: {
    submitting: 'Bekræftelse ...',
    success_message: 'Konto bekræftet',
    error_message: 'Kunne ikke bekræfte konto',
    email_sent: 'Bekræftelses e-mail er sendt',
    go_back: 'Gå til min konto'
  },
  passwordless: {
    submitting: 'Vent venligst...',
    error_message: 'Linket er udløbet',
    go_back: 'Gå til login'
  },
  dashboard: {
    title: 'Min Konto',
    recent_orders: 'Seneste ordrer',
    view_all_orders: 'Se alle ordrer',
    summary: 'Kontooplysninger',
    edit_link: 'Redigér Konto',
    addresses_link: 'Administrér adresser'
  },
  orders: {
    title: 'Ordrer',
    no_orders: 'Du har endnu ikke afgivet ordrer',
    col_number: 'Ordrenummer',
    col_date: 'Dato',
    col_address: 'Adresse',
    col_status: 'Status',
    col_total: 'Total',
    view_order: 'Gennemse ordre',
    status_cancelled: 'Annulleret',
    status_fulfilled: 'Afsendt',
    status_in_progress: 'Under behandling',
    status_on_hold: 'På hold',
    status_open: 'Åben',
    status_partially_fulfilled: 'Delvist behandlet',
    status_pending_fulfillment: 'Afventer behandling',
    status_restocked: 'Tilbage på lager',
    status_scheduled: 'Planlagt',
    status_processing: 'Under behandling'
  },
  order_view: {
    number: 'Ordre #{number}',
    print_action: 'Print',
    not_found: 'Ordren blev ikke fundet',
    details: 'Ordreoplysninger',
    cancelled_title: `Ordren annulleret den {date}`,
    cancelled_reason: `Årsag: {text}`,
    order_date: 'Dato for ordre',
    payment_status: 'Status for betaling',
    order_status: 'Status for ordre',
    customer: 'Kunde',
    shipping_address: 'Leveringsadresse',
    billing_address: 'Betalingsadresse',
    delivery: 'Leveringsoversigt',
    delivery_by: 'Leveret af {company}',
    track_delivery: 'Følg leveringen',
    products: 'Bestil produkter',
    col_product: 'Produkt',
    col_quantity: 'Antal',
    col_price: 'Pris',
    col_subtotal: 'Subtotal',
    shipping: 'Levering',
    tax: 'Afgift',
    total: 'Total',
    refunded: 'Refunderet'
  },
  manage_account: {
    title: 'Administrér konto',
    heading: 'Personlige data',
    first_name: 'Fornavn',
    last_name: 'Efternavn',
    email: 'Email',
    marketing_opt_in: 'Jeg vil gerne modtage nyhedsbreve',
    save_action: 'Gem ændringer',
    change_password_error_mismatch: 'Nuværende adgangskode er forkert',
    change_password_error_incorrect: 'Forkert adgangskode',
    change_password_error_generic: 'Problem med at opdatere adgangskode'
  },
  change_password: {
    heading: 'Skift adgangskode',
    current: 'Nuværende adgangskode',
    new: 'Ny adgangskode',
    confirm: 'Bekræft ny adgangskode',
    action: 'Opdateret adgangskode',
    error_no_match: 'Adgangskoden matcher ikke',
    success_message: 'Adgangskode opdateret'
  },
  addresses: {
    title: 'Administrér adresser',
    add_address: 'Tilføj adresse',
    no_results: 'Du har endnu ikke tilført addresser',
    default_address: 'Standardadresse',
    edit_action: 'Redigér ',
    delete_action: 'Slet',
    delete_prompt: 'Er du sikker på, at du vil fjerne denne adresse?',
    delete_popup_cancel: 'Annulér',
    delete_popup_confirm: 'Ja, Fjern',
    success_deleted: 'Adressen er fjernet',
    error_deleting: 'Problemer med at fjerne adresse'
  },
  address_form: {
    heading_add: 'Tilføj adresse',
    heading_edit: 'Redigér adresse',
    first_name: 'Fornavn',
    last_name: 'Efternavn',
    address_line_1: 'Adresse 1',
    address_line_2: 'Adresse 2',
    postcode: 'Postnummer',
    city: 'By',
    state: 'Stat',
    country: 'Land',
    set_default: 'Opført som standardadresse',
    add_action: 'Opret adresse',
    edit_action: 'Gem ændringer',
    cancel_action: 'Annullér'
  },
  assessment: {
    title: 'Håranalyse',
    no_assessment: 'Du har endnu ikke færdiggjort din Håranalyse',
    take_assessment: 'Tag Håranalysen',
    heading: 'Resultatet af din seneste Håranalyse',
    complete_date: 'Håranalyse udført den {date}',
    recommended_title: 'Anbefalet til dig',
    view_result: 'Se resultat'
  }
}

export default locale
