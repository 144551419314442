import type { DeepPartial } from 'har-shared/types/common'
import type { LocaleMessageSchema } from '@/locale/types'

const locale: DeepPartial<LocaleMessageSchema> = {
  common: {
    or: 'eða',
    input_email: 'Netfang',
    input_password: 'Lykilorð',
    social_login: 'Halda áfram með {name}',
    required: 'Áskilið',
    error_required: 'Áskilið',
    error_captcha: 'Vinsamlegast staðfestu að þú sért manneskja',
    per_page: 'á hverja síðu',
    next: 'Næsta',
    prev: 'Fyrri',
    changes_saved: 'Breytingar vistaðar',
    loading_captcha: 'Hleður CAPTCHA, vinsamlegast bíðið...'
  },
  localization: { heading: 'Veldu landið þitt', action: 'Halda áfram' },
  oauth: {
    wait_message: 'Vinsamlegast bíðið...',
    close_link: 'Farðu aftur til að skrá þig inn'
  },
  login: {
    init_heading: 'Skrá inn eða Búa til reikning',
    init_subheading: 'Velkomin í Harklinikken, sláðu inn netfangið þitt til að halda áfram',
    init_email_placeholder: 'Sláðu inn netfangið þitt hér',
    continue_action: 'Halda áfram',
    invalid_email: 'Ógilt tölvupóstsnið',
    heading: 'Skrá inn',
    subheading: 'Velkomin(n) aftur, sláðu inn aðgangsorðin þín til að skrá þig inn',
    action: 'Skrá inn',
    otp_input: 'Kóði fyrir eitt skipti',
    otp_subheading:
      'Við sendum þér kóða fyrir eitt skipti á {email}. Sláðu kóðann inn hér fyrir neðan.',
    otp_placeholder: 'Sláðu inn einskiptakóðann þinn hér',
    otp_note: 'Afhending OTP tölvupósts getur tekið allt að tvær mínútur.',
    otp_request_link: 'Biðja um nýjan kóða',
    otp_login_error_heading: 'Innskráning mistókst',
    otp_login_error: 'Vinsamlegast biðjið um nýjan einskiptakóða og reyndu aftur.',
    passwordless_subheading:
      'Við sendum innskráningartengil á {email}. Fylgdu leiðbeiningunum í tölvupóstinum.',
    forgot_password: 'Gleymdirðu lykilorðinu?',
    no_account_prompt: 'Ertu ekki með reikning?',
    register_link: 'Búa til reikning',
    message_login_facebook:
      'Reikningurinn þinn er tengdur við Facebook, smelltu á hnappinn hér að neðan til að skrá þig inn',
    message_login_google:
      'Reikningurinn þinn er tengdur við Google, smelltu á hnappinn hér að neðan til að skrá þig inn',
    message_login_social:
      'Þú hefur búið til reikninginn þinn með félagslegri innskráningu, vinsamlegast notaðu hnappana hér að neðan'
  },
  register: {
    heading: 'Búa til reikning',
    subheading: 'Með því að búa til reikning samþykkirðu {terms} og {privacy}',
    terms: 'þjónustuskilmála',
    privacy: 'persónuverndarstefnu',
    opt_in_label: 'Já, mig langar til að fá auglýsingapósta frá Harklinikken.',
    action: 'Halda áfram',
    login_prompt: 'Þegar með reikning?',
    login_action: 'Skrá inn',
    message_success: 'Reikningur búinn til, vinsamlegast skráðu þig inn til að halda áfram'
  },
  forgot_password: {
    heading: 'Gleymdirðu lykilorðinu?',
    body: 'Vinsamlegast sláðu inn netfangið þitt og við sendum þér hlekk til að endurstilla lykilorðið þitt',
    action: 'Biðja um endurstillingartengil',
    login_action: 'Farðu aftur til innskráningar'
  },
  reset_password: {
    heading: 'Endur stilla lykilorð',
    body: 'Vinsamlega stilltu nýja lykilorðið þitt',
    input_new_password: 'Nýtt lykilorð',
    action: 'Uppfærðu lykilorð',
    forgot_action: 'Biðja um nýjan endurstillingartengil'
  },
  layout: {
    account_menu: 'Matseðill',
    manage_account: 'Stjórna Reikningi',
    logout: 'Útskráning',
    sidebar_menu: 'Reikningsvalmynd',
    link_overview: 'Yfirlit',
    link_manage_account: 'Mínar upplýsingar',
    link_manage_addresses: 'Sendingar. upplýsingar',
    link_orders: 'Pantanir',
    link_subscriptions: 'Áskriftir',
    link_hair_assessment: 'Hárpróf',
    link_refer: 'Vísa vini',
    link_referrals: 'Tilvísanir',
    link_go_to_store: 'Farðu aftur í búðina',
    error_multipass:
      'Vandamál við að fá aðgang að reikningnum þínum, vinsamlegast hafðu samband við þjónustudeild',
    error_shopify:
      'Vandamál við að sækja verslunarreikninginn þinn, vinsamlegast hafðu samband við þjónustudeild',
    try_again: 'Reyndu aftur',
    not_verified_heading: 'Staðfestu netfangið þitt',
    not_verified_body:
      'Til að halda hlutunum öruggum og tryggja að reikningurinn þinn sé varinn, vinsamlegast staðfestu netfangið þitt með því að smella á hlekkinn í móttökupóstinum sem við sendum þér.',
    not_verified_resend: 'Endursenda staðfestingartölvupóst'
  },
  verify: {
    submitting: 'Staðfestir...',
    success_message: 'Reikningur staðfestur',
    error_message: 'Tókst ekki að staðfesta reikning',
    email_sent: 'Staðfestingartölvupóstur sendur',
    go_back: 'Farðu á reikninginn minn'
  },
  passwordless: {
    submitting: 'Vinsamlegast bíddu...',
    error_message: 'Tengill er útrunninn',
    go_back: 'Farðu í innskráningu'
  },
  dashboard: {
    title: 'Reikningurinn minn',
    recent_orders: 'Nýlegar pantanir',
    view_all_orders: 'Skoða allar pantanir',
    summary: 'Reikningsupplýsingar',
    edit_link: 'Breyta reikningi',
    addresses_link: 'Stjórna heimilisföngum'
  },
  orders: {
    title: 'Pantanir',
    no_orders: 'Þú hefur ekki gert neinar pantanir ennþá',
    col_number: 'Pöntunarnúmer',
    col_date: 'Date',
    col_address: 'Heimilisfang',
    col_status: 'Staða',
    col_total: 'Total',
    view_order: 'Skoða pöntun',
    status_cancelled: 'Hætt við',
    status_fulfilled: 'Uppfyllt',
    status_in_progress: 'Í vinnslu',
    status_on_hold: 'Í bið',
    status_open: 'Opið',
    status_partially_fulfilled: 'Að hluta til uppfyllt',
    status_pending_fulfillment: 'Biður uppfyllingar',
    status_restocked: 'Restocked',
    status_scheduled: 'Áætlað',
    status_processing: 'Í vinnslu'
  },
  order_view: {
    number: 'Pöntun #{number}',
    print_action: 'Prenta',
    not_found: 'Pöntun fannst ekki',
    details: 'Pöntunarupplýsingar',
    cancelled_title: `Pöntun aflýst {date}`,
    cancelled_reason: `Ástæða: {texti}`,
    order_date: 'Pöntunardagur',
    payment_status: 'Greiðslustaða',
    order_status: 'Pöntunarstaða',
    customer: 'Viðskiptavinur',
    shipping_address: 'Sendingarheimilisfang',
    billing_address: 'Heimilisfang innheimtu',
    delivery: 'Afhendingarupplýsingar',
    delivery_by: 'Afhent með {company}',
    track_delivery: 'Fylgstu með afhendingu',
    products: 'Panta vörur',
    col_product: 'Vara',
    col_quantity: 'Magn',
    col_price: 'Verð',
    col_subtotal: 'Samtala',
    shipping: 'Sending',
    tax: 'Skattur',
    total: 'Samtals',
    refunded: 'Endurgreitt'
  },
  manage_account: {
    title: 'Stjórna reikningi',
    heading: 'Persónuupplýsingar',
    first_name: 'Fornafn',
    last_name: 'Eftirnafn',
    email: 'Netfang',
    marketing_opt_in: 'Ég vil fá markaðspóst',
    save_action: 'Vista breytingar',
    change_password_error_mismatch: 'Núverandi lykilorð er rangt',
    change_password_error_incorrect: 'Rangt lykilorð',
    change_password_error_generic: 'Vandamál við að uppfæra lykilorð'
  },
  change_password: {
    heading: 'Breyta lykilorði',
    current: 'Núverandi lykilorð',
    new: 'Nýtt lykilorð',
    confirm: 'Staðfestu nýtt lykilorð',
    action: 'Uppfærðu lykilorð',
    error_no_match: 'Lykilorð passa ekki saman',
    success_message: 'Lykilorð uppfært'
  },
  addresses: {
    title: 'Stjórna heimilisföngum',
    add_address: 'Bæta við heimilisfangi',
    no_results: 'Þú ert ekki með nein heimilisföng ennþá',
    default_address: 'Sjálfgefið heimilisfang',
    edit_action: 'Breyta',
    delete_action: 'Eyða',
    delete_prompt: 'Ertu viss um að þú viljir fjarlægja þetta netfang?',
    delete_popup_cancel: 'Hætta við',
    delete_popup_confirm: 'Já, fjarlægja',
    success_deleted: 'Heimilisfangi eytt',
    error_deleting: 'Vandamál við að eyða heimilisfangi'
  },
  address_form: {
    heading_add: 'Bæta við heimilisfangi',
    heading_edit: 'Breyta heimilisfangi',
    first_name: 'Fyrsta nafn',
    last_name: 'Eftirnafn',
    address_line_1: 'Heimilisfang 1',
    address_line_2: 'Heimilisfang lína 2',
    postcode: 'Póstnúmer',
    city: 'Borg',
    state: 'Ríki',
    country: 'Land',
    set_default: 'Stillt sem sjálfgefið heimilisfang',
    add_action: 'Create Address',
    edit_action: 'Vista breytingar',
    cancel_action: 'Hætta við'
  },
  assessment: {
    title: 'Hárpróf',
    no_assessment: 'Þú hefur ekki lokið hármati ennþá',
    take_assessment: 'Taktu Hárpróf',
    heading: 'Nýjasta hármatsniðurstaðan þín',
    complete_date: 'Mat gert {date}',
    recommended_title: 'Mælt með fyrir þig',
    view_result: 'Skoða niðurstöðu'
  }
}

export default locale
