import enLocale from './en'
import daLocale from './da'
import isLocale from './is'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  locales: ['en', 'da', 'is'],
  defaultLocale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: enLocale,
    da: daLocale,
    is: isLocale,
    'en-eu': enLocale,
    'en-ae': enLocale,
    'en-us': enLocale
  },
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'GBP',
        notation: 'compact' // 'standard'
      }
    },
    'en-eu': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'compact' // 'standard'
      }
    },
    'en-ae': {
      currency: {
        style: 'currency',
        currency: 'AED',
        notation: 'compact' // 'standard'
      }
    },
    'en-us': {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'compact' // 'standard'
      }
    },
    da: {
      currency: {
        style: 'currency',
        currency: 'DKK',
        notation: 'compact' // 'standard'
      }
    },
    is: {
      currency: {
        style: 'currency',
        currency: 'ISK',
        notation: 'compact' // 'standard'
      }
    }
  },
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    },
    da: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    }
  }
}))
